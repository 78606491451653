// make abous-us class text smaller
.about-us,
.footer-adress,
.social-footer {
    font-size: 0.8rem;
}

.social-footer {
    // Change the color of the li elements in the footer to #0acf83
    li a {
        color: #fff;
        font-weight: 500;
    }
}

.footer-subtitle {
    font-size: 0.8rem;
    font-weight: 600;
    color: #0acf83;
}
