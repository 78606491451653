.arrow-icon {
    animation-name: arrow-bounce;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes arrow-bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

// Add padding to top of page content equal to navbar height
.page-content {
    padding-top: 60px; // Change this value to match your navbar height
}

.quote {
    font-family: 'Fire Code', monospace;
    font-weight: 700;
}

.fw-bolder {
    font-weight: 700 !important;
}

.fw-bold {
    font-weight: 500 !important;
}

.card {
    height: 100%;
    // Remove rounded corners and borders and add a nice shadow effect
    border-radius: 0;
    border: none;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    padding: 3rem 0 3rem 0;
}
