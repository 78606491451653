.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.main-modal {
    width: 100% !important;
}

.modal-dialog {
    width: 40vw;
    max-width: 40vw;
}

@media (max-width: 576px) {
    .modal-dialog {
        width: 90vw;
        max-width: 90vw;
    }
}
