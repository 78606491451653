@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Josefin+Sans:wght@300;400;500;600;700&family=Poppins:wght@400;500;600;700;800;900&display=swap');
body {
    font-family: 'Poppins', sans-serif;
}

hr {
    height: 2px;
    background-color: #0acf83 !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
    background-color: #0acf83 !important;
    border: none;
}

// Make href links look white without underline and when hover, green color
a {
    color: white;
    text-decoration: none;
}

a:hover {
    color: #0acf83;
}
