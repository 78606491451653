.navbar-white {
    .navbar-nav {
        .nav-link.custom-active,
        .nav-link.custom-active:hover,
        .nav-link.custom-active:focus {
            color: #0acf83 !important; /* green color */
            /* Underline  */
            border-bottom: 4px solid #0acf83 !important;
            font-weight: 700 !important;
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    animation: slideDown 0.5s ease forwards;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.inactive-link {
    color: #000 !important;
    font-weight: 500;
}

.navbar-toggler {
    border: none;
}

@media (min-width: 1024px) {
    .navbar-toggler {
        display: none;
    }
}
